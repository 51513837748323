import React from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import MusicInsight from "./MusicInsight";
import SpotifyService from "./SpotifyService";
import './App.css';
import Typography from "@material-ui/core/Typography";
import heart from './media/heart.svg';
import heartFull from './media/heartFull.svg';
import Avatar from "@material-ui/core/Avatar";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


class Main extends React.Component {
    constructor(props) {
        super(props);
        const params = this.getHashParams();
        const token = params.access_token;
        if (token) {
            SpotifyService.setToken(token);
        }
        this.state = {
            authCode: null,
            loggedIn: !!token,
            nowPlaying: { name: 'Not Playing', albumArt: '', id: -1, artist: "" },
            isInLibrary: false,
            topArtists: [],
            topTracks: [],
        };
    }

    componentDidMount() {
        if(this.state.loggedIn)
        {
            this.getNowPlaying();
            this.getTopTracks();
            this.getTopArtists();
        }
    }

    getHashParams() {
        var hashParams = {};
        var e, r = /([^&;=]+)=?([^&;]*)/g,
            q = window.location.hash.substring(1);
        e = r.exec(q)
        while (e) {
            hashParams[e[1]] = decodeURIComponent(e[2]);
            e = r.exec(q);
        }
        return hashParams;
    }

    transformArtistsIntoString(artists) {
        let artistsArray = [];
        artists.forEach((artist) => artistsArray.push(artist.name));
        return artistsArray.join(', ');
    }

    getNowPlaying(){
        let isInLibrary = false;
        SpotifyService.getMyCurrentPlaybackState().then((response) => {
            if (response.item == null)
                return null;
            let artists = this.transformArtistsIntoString(response.item.artists);
            SpotifyService.containsMySavedTracks([response.item.id]).then((libResponse) => {
                isInLibrary = libResponse[0];
                if(isInLibrary === undefined)
                    isInLibrary = false;
                this.setState({
                    nowPlaying: {
                        name: response.item.name,
                        albumArt: response.item.album.images[1].url,
                        artist: artists,
                        id: response.item.id,
                    },
                    isInLibrary: isInLibrary,
                });
            });
        })
    }

    getTopTracks() {
        let tracks = [];
        SpotifyService.getMyTopTracks()
            .then((response) => {
                if (response.items == null)
                    return null;
                let trackCollection = response.items;
                for(let i = 0; i < 5; i++) {
                    let artists = this.transformArtistsIntoString(trackCollection[i].artists);
                    tracks.push({name: trackCollection[i].name, artists: artists, image: trackCollection[i].album.images[2].url, id: trackCollection[i].id})
                }
                this.setState({
                    topTracks: tracks
                })
            })
            .catch((err) => {
                console.log(err);
            });
    }

    getTopArtists() {
        let artists = [];
        SpotifyService.getMyTopArtists()
            .then((response) => {
                if (response.items == null)
                    return null;
                let artistsCollection = response.items;
                for (let i = 0; i < 5; i++) {
                    artists.push({name: artistsCollection[i].name, image: artistsCollection[i].images[2].url, id: artistsCollection[i].id});
                }
                this.setState({
                    topArtists: artists,
                })
            })
            .catch((err) => {
                console.log(err);
            });
    }

    onLikeTrack() {
        if (this.state.isInLibrary)
            return;
        SpotifyService.addToMySavedTracks({ids: [this.state.nowPlaying.id]});
        this.setState({
            isInLibrary: true,
        })
    }

    render() {
        return (
            <div>
            <div className={'Container'}>
                <h1 className={'Header'}>MusicTaste</h1>
                {!this.state.loggedIn &&
                <div className={"Login"}>
                    <Button variant={'outlined'} style={{margin: 40, backgroundColor: '#1c9b4c'}}
                            href='http://music.denkenist.gratis:8888'> Login to Spotify </Button>
                </div>
                }
                { this.state.loggedIn &&
                <div className={'VerticalMainContainer'}>
                    <div>
                        <Typography variant={'h2'} style={{color: 'white', fontWeight: 500}}>
                            Top Tracks
                        </Typography>
                        {this.state.topTracks.map((item) => {
                            return (
                                <Card variant={'outlined'} className={'SmallCardItem Left'}>
                                    <CardHeader title={item.name} subheader={item.artists} avatar={<Avatar src={item.image}/>}/>
                                </Card>
                            )
                        })}
                    </div>
                    <div className={'MainContainer'}>
                        <Card variant={"outlined"} className={'CardItem'}>
                            <CardHeader title={this.state.nowPlaying.name} subheader={this.state.nowPlaying.artist}/>
                            <CardMedia image={this.state.nowPlaying.albumArt} className={'AlbumArt'}/>
                        </Card>
                        <img src={this.state.isInLibrary === true ? heartFull : heart}
                             style={{height: 20, width: 20, margin: 20,}} onClick={() => this.onLikeTrack()}/>
                        <Button style={{margin: 40, backgroundColor: '#1c9b4c'}} className={'RefreshButton'}
                                variant="outlined" onClick={() => this.getNowPlaying()}>
                            Check Now Playing
                        </Button>
                        <ExpandMoreIcon fontSize={'large'} onClick={() => {

                        }}/>
                    </div>
                    <div>
                        <Typography variant={'h2'} style={{color: 'white', fontWeight: 500}}>
                            Top Artists
                        </Typography>
                        <div>
                            {this.state.topArtists.map((item) => {
                                return(
                                <Card variant={'outlined'} className={'SmallCardItem Right'}>
                                    <CardHeader title={item.name}  avatar={<Avatar src={item.image}/>}/>
                                </Card>
                                )
                            })}
                        </div>
                    </div>
                </div>
                }
            </div>
                { this.state.loggedIn && this.state.nowPlaying.id !== -1 &&
                    <MusicInsight id={this.state.nowPlaying.id} />
                }
            </div>
        );
    }
}

export default Main;