import React from 'react';
import SpotifyService from "./SpotifyService";
import {XYPlot, ArcSeries} from 'react-vis';
import Typography from "@material-ui/core/Typography";

const colorArray = ['#ff9500', '#2600ff', '#ffff00', '#fb00ff', '#09ff00', '#00ccff'];

const colorFeatures = {acousticness: 0, danceability: 1, energy: 2, instrumentalness: 3, speechiness: 4, valence: 5};

class MusicInsight extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            features: {
                acousticness: 0.0,
                danceability: 0.0,
                energy: 0.0,
                instrumentalness: 0.0,
                speechiness: 0.0,
                valence: 0.0,
            },
            duration: 0.0,
            bpm: 0.0,
            id: this.props.id
        }
    }

    componentDidMount() {
        this.getAudioFeatures();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps !== this.props) {
            this.getAudioFeatures();
        }
    }

    getAudioFeatures() {
        SpotifyService.getAudioFeaturesForTrack(this.state.id)
            .then((response) =>  {
            try {
                let duration_sec = response.duration_ms / 1000;
                this.setState({
                    features: {
                        acousticness: response.acousticness,
                        danceability: response.danceability,
                        energy: response.energy,
                        instrumentalness: response.instrumentalness,
                        speechiness: response.speechiness,
                        valence: response.valence,
                    },
                    duration: duration_sec,
                    bpm: response.tempo,
                    id: this.props.id,
                });
            } catch (e) {
                console.log(e);
            }
            }).catch((err) => {
                console.log(err);
        });
    }

    render() {
        let features = this.state.features;
        // graph items
        let data = [];
        // legend items
        let legendData = [];
        // sort features
        let sortable = [];
        for (let item in features) {
            sortable.push([item, features[item]]);
        }
        sortable.sort(function(a, b) {
            return a[1] - b[1];
        });
        // reverse for push
        sortable.reverse();
        let featuresSorted = {};
        sortable.forEach(function(item){
            featuresSorted[item[0]]=item[1]
        });
        // draw graph
        let i = 0;
        let header = ["h1", "h2", "h3", "h4", "h5", "h6"];
        for (let [key, value] of Object.entries(features)) {
            if(value >= 0 && value <= 1) {
                let sortedKey = Object.keys(featuresSorted)[i]
                // unsorted data
                data.push({angle0: i * Math.PI / 3, angle: (i+1) * Math.PI / 3, radius0: -value, radius: 0, color: colorFeatures[key]});
                // sorted legend
                legendData.push(
                    <Typography variant={header[i]} style={{color: colorArray[colorFeatures[sortedKey]]}}>
                        {sortedKey}
                    </Typography>
                );
            }
            i++;
        }
        // graph legend
        let legend = (
            <div>
                {legendData}
            </div>
        );

        return (
            <div className={'Container InsightContainer'}>
                <div className={'GraphContainer'}>
                <XYPlot
                    xDomain={[-5, 5]}
                    yDomain={[-5, 5]}
                    width={500}
                    height={500}>
                    <ArcSeries
                        animation={{duration: 400}}
                        data={data}
                        colorDomain={[0, 1, 2, 3, 4, 5, 6]}
                        colorRange={colorArray}
                        colorType='linear'/>
                </XYPlot>
                { legend }
                </div>
            </div>
        );
    }
}

export default MusicInsight;
