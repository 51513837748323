import SpotifyWebApi from "spotify-web-api-js";

const spotifyApi = new SpotifyWebApi();

class SpotifyService {
    static setToken(token) {
        spotifyApi.setAccessToken(token);
    }
    static getMyCurrentPlaybackState() {
        return spotifyApi.getMyCurrentPlaybackState();
    }

    static getAudioFeaturesForTrack(id) {
        try {
           return spotifyApi.getAudioFeaturesForTrack(id.toString());
        } catch (e) {
            console.log("getAudioFeaturesForTrack Error:")
            console.log(e);
        }
    }

    static getMyTopTracks() {
        return spotifyApi.getMyTopTracks();
    }

    static getMyTopArtists() {
        return spotifyApi.getMyTopArtists();
    }

    static getUserPlaylists() {
        let userId = this.getUserId();
        return spotifyApi.getUserPlaylists(userId);
    }

    static getUserId() {
        spotifyApi.getUser().then((response) => {
            return response.id;
        })
    }

    static addToMySavedTracks(idArray) {
        spotifyApi.addToMySavedTracks(idArray).then(() => {
        }).catch((err) => {
            console.log(err);
        })
    }

    static containsMySavedTracks(idArray) {
        return spotifyApi.containsMySavedTracks(idArray);
    }
}

export default SpotifyService;